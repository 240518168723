<template>
  <div class="container-right">
    <div class="container-right-name">
        版权相关
        <div class="line"></div>
    </div>
    <div class="collapse">
      <el-collapse>
        <el-collapse-item title="什么是著作权？" name="1">
          <div class="p">
            著作权又称版权，是指作者对其创作的文学、艺术和科学技术作品所享有的专有权利。著作权是公民、法人依法享有的一种民事权利，属于无形财产权。
<br>
著作权法所称的作品，包括以下列形式创作的文学、艺术和自然科学、社会科学、工程技术等作品：（一）文字作品；（二）口述作品；（三）音乐、戏剧、曲艺、舞蹈、杂技艺术作品；（四）美术、建筑作品；（五）摄影作品；（六）电影作品和以类似摄制电影的方法创作的作品；（七）工程设计图、产品设计图、地图、示意图等图形作品和模型作品；（八）计算机软件；（九）法律、行政法规规定的其他作品。
<br>
在中华人民共和国境内，凡是中国公民，法人或者非法人单位的作品，不论是否发表都享有著作权；外国人的作品首先在中国境内发表的，也依著作权法享有著作权；外国人在中国境外发表的作品，根据其所属国与中国签订的协议或者共同参加的国际条约享有著作权。
          </div>
        </el-collapse-item>
        <el-collapse-item title="美术作品原件所有人享有著作权吗？" name="2">
          <div class="p">
            美术作品原件所有人拥有原件的所有权和使用权。但能否享有著作权取决于作者本人是否授权原件所有人进行二次创作并允许商业使用。
          </div>
        </el-collapse-item>
        <el-collapse-item title="用户原创的作品，包含哪些权力？" name="3">
          <div class="p">
            具体包含的各项权利参见《中华人民共和国著作权法》第十条——著作权包括下列人身权和财产权：<br>
（一）发表权，即决定作品是否公之于众的权利；<br>
（二）署名权，即表明作者身份，在作品上署名的权利；<br>
（三）修改权，即修改或者授权他人修改作品的权利；<br>
（四）保护作品完整权，即保护作品不受歪曲、篡改的权利；<br>
（五）复制权，即以印刷、复印、拓印、录音、录像、翻录、翻拍等方式将作品制作一份或者多份的权利；<br>
（六）发行权，即以出售或者赠与方式向公众提供作品的原件或者复制件的权利；<br>
（七）出租权，即有偿许可他人临时使用电影作品和以类似摄制电影的方法创作的作品、计算机软件的权利，计算机软件不是出租的主要标的的除外；
<br>
（八）展览权，即公开陈列美术作品、摄影作品的原件或者复制件的权利；<br>
（九）表演权，即公开表演作品，以及用各种手段公开播送作品的表演的权利；<br>
（十）放映权，即通过放映机、幻灯机等技术设备公开再现美术、摄影、电影和以类似摄制电影的方法创作的作品等的权利；<br>
（十一）广播权，即以无线方式公开广播或者传播作品，以有线传播或者转播的方式向公众传播广播的作品，以及通过扩音器或者其他传送符号、声音、图像的类似工具向公众传播广播的作品的权利；
<br>
（十二）信息网络传播权，即以有线或者无线方式向公众提供作品，使公众可以在其个人选定的时间和地点获得作品的权利；<br>
（十三）摄制权，即以摄制电影或者以类似摄制电影的方法将作品固定在载体上的权利；<br>
（十四）改编权，即改变作品，创作出具有独创性的新作品的权利；<br>
（十五）翻译权，即将作品从一种语言文字转换成另一种语言文字的权利；<br>
（十六）汇编权，即将作品或者作品的片段通过选择或者编排，汇集成新作品的权利；<br>
（十七）应当由著作权人享有的其他权利。<br>
著作权人可以许可他人行使前款第（五）项至第（十七）项规定的权利，并依照约定或者本法有关规定获得报酬。<br>
著作权人可以全部或者部分转让本条第一款第（五）项至第（十七）项规定的权利，并依照约定或者本法有关规定获得报酬。<br>
 </div>
        </el-collapse-item>
        <el-collapse-item title="将他人作品进行改编后，改编者对改编的作品是否享有完整的著作权？" name="4">
          <div class="p">
            《中华人民共和国著作权法》第十二条规定：“改编、翻译、注释、整理已有作品而产生的作品，其著作权由改编、翻译、注释、整理人享有，但行使著作权时不得侵犯原作品的著作权。”
            <br>
这里需指出，在获得原作者授权同意进行改编的情况下，改编者可以获得改编作品的著作权。但此处的“改编”应区别于“改动”。
</div>
          
        </el-collapse-item>
      </el-collapse>
    </div>
      
  </div>
</template>

<script>
export default {
  name: 'CopyRight',
 components:{},
  data () {
    return {
    }
  },

  methods: {}
}
</script>

<style lang='scss' scoped>
@import '../../assets/css/mine.scss';
.collapse{
  p{
  color: rgba(0, 18, 22, 0.65);
  }
}

</style>
